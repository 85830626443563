<template>
  <div id="menubody">
    <div class="showbody">
      <ul class="showul">
        <span v-if="totalnum!=0?false:true"
        class="showulspan"
        >{{$t('m.reservation.No_data_found')}}!!</span>
        <li class="showli"
         v-for="(item,index) in yihuoList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <!-- <span class="yushouCont">会员专享</span> -->

            <img :src="item.pic" alt="">
            <p class="p1 ptitle">
              {{$t('m.barter.existing_products')}}：{{item.name}}
            </p>
            <p class="p1">
              <span>
                <i class="zititi c1">{{$t('m.barter.inventory')}}：</i>{{item.stock}}
                <i class="zititi">{{item.unit}}</i>
              </span>
            </p>
            <!-- <p class="zititi">转售数：<i class="p2">{{item.number}}</i></p> -->
            <p class="zititi">{{$t('m.barter.intended_exchange_commodity')}}：<i class="p2">{{item.pmsExchangeSkuStockEntity.demandProductName}}</i></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="showfooter" v-if="totalnum<pagesize?false:true">
      <el-pagination
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="totalnum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
// import emitter from '../../tools/bus'
import http from '../../api/http'
import { getStore } from '../../tools/storage'
import { ElMessage } from 'element-plus'
// import { useRouter } from 'vue-router'
// 查询易货大厅数据
const getExchangeProduct = (data) => {
  return http.get('/mall-portal/portal/pmsexchangeproduct/getExchangeProduct', data)
}
export default {
  name: 'BarterBody',
  data () {
    return {
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(16),
      // 默认展示页
      currentPage: ref(1),
      yihuoList: reactive([])// 展示数据
    }
  },
  methods: {
    async initdata (val) {
      const loading = this.$loading({
        lock: true,
        text: '请稍候...'
      })
      const data = {
        pageNum: val,
        pageSize: 8
      }
      try {
        const res = await getExchangeProduct(data)
        if (res.data.code === 200) {
          this.yihuoList = res.data.data.list
          this.totalnum = res.data.data.total
          // console.log(this.yihuoList)
          console.log(res.data)
          loading.close()
        }
      } catch (error) {
        loading.close()
        console.log(error)
      }
    },
    toDetails (data) {
      console.log(data)
      if (!JSON.parse(getStore('usermessage'))) {
        ElMessage.warning({
          message: '没有找到登录信息！！'
        })
        return
      }
      // const barterMember = JSON.parse(getStore('usermessage')).barterMember
      // if (barterMember) {
      //   this.$router.push({
      //     path: '/barter/detail',
      //     query: {
      //       id: data.id
      //     }
      //   })
      // } else {
      //   ElMessage.warning({
      //     message: '您没有权限查看，请先成为易货会员！'
      //   })
      //   return ''
      // }
      this.$router.push({
        path: '/barter/detail',
        query: {
          id: data.id
        }
      })
    },
    // 分页
    handleCurrentChange (val) {
      console.log(`current page: ${val}`)
      this.initdata(val)
    }
  },
  mounted () {
    this.initdata()
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';
#menubody{
  margin-top: 10px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
  .showbody{
    width: 100%;
    flex: 1;
    .showul{
      min-height: 100px;
      // background: $divbgColor;
      .showulspan{
        display: inline-block;
        width: 100%;
        color: #AC703B;
        line-height: 100px;
        font-size: 30px;
        text-align: center;
      }
      .showli{
        float: left;
        width: 300px;
        box-sizing: border-box;
        padding: 20px;
        background: $divbgColor;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        cursor: pointer;
        .yscont{
          position: relative;
          .yushouCont{
            width: 60px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #ac3d3b 0%, #FFBC88 100%);
            border-radius: 0px 0px 4px 0px;
            position: absolute;
          }
          p{
            line-height: 35px;
          }
          img{
            width: 260px;
            height: 260px;
          }
          .p1{
            color: #333333;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            width: 260px;
            span{
              display: inline-block;
              // float: right;
              text-align: right;
              color: #c88756;
              font-size: 20px;
              .c1{
                color: #c88756;
              }
            }
          }
          .ptitle{
            line-height: 21px;
            margin: 10px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .zititi{
            color: #999999;
            font-size: 14px;
            .p2{
              color: #333333;
            }
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
