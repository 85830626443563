<template>
<div class='barter'>
  <div class="header">
    <header-base></header-base>
    <!-- <header-base :barterBtn="{title:'添加易货商品', to:'/merchantworkbench' }"></header-base> -->
  </div>
  <div class="body">
    <div class="content">
      <div class="banner">
        <div class="ban_left">
          <div><p>{{$t('m.barter.Barter_transaction_process')}}</p></div>
          <img src="../../static/imges/yhLC.png" alt="">
        </div>
        <div class="ban_center">
          <el-carousel height="420px"
          ref="carousel"
          :interval="4000" arrow="hover">
            <el-carousel-item v-for="item in bannerImg" :key="item">
              <!-- <h3>{{ item }}</h3> -->
              <img :src="item.pic" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="ban_right">
          <!-- 图表 -->
          <p>{{$t('m.reservation.Transaction_dynamics')}}</p>
          <div class="right_table">
            <div id="table_head">
              <tr>
                <th v-for="(i,index) in dataList.title" :key="index">
                  {{i}}
                </th>
              </tr>
            </div>
            <vue-seamless-scroll
            :data="dataList.cont"
            :class-option="optionHover"
            class="seamless-warp">
              <div id="table_body">
                <table>
                  <tr
                  v-for="(k,index1) in dataList.cont" :key="index1">
                    <td style="width: 60px;">{{k.exchangeProductName}}</td>
                    <td style="flex:1;">{{k.sellQuantity}}</td>
                    <td style="flex:1;">——</td>
                    <td style="flex:1;">{{k.quantity}}</td>
                    <td style="width: 60px;text-align: center;">{{k.demandProductName}}</td>
                  </tr>
                </table>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>

      <div class="msg">
        <p>{{$t('m.barter.news')}}</p>
        <el-scrollbar max-height="440px" v-loading="loading">
          <div class="zixun">
            <h3 v-for="(i,k) in zixunList" :key="k">
              {{(k+1)+'、'}}{{i.title}}
            </h3>
          </div>
        </el-scrollbar>
      </div>
      <!-- <div class="msg">
        <div class="left">
          <p>成交动态</p>
          <div class="right_table">
            <vue-seamless-scroll
            :data="dataList.cont"
            :class-option="optionHover"
            class="seamless-warp">
              <div id="table_body">
                <table>
                  <tr
                  v-for="(k,index1) in dataList.cont" :key="index1">
                    <td style="width: 60px;">{{k.name}}</td>
                    <td style="flex:1;">{{k.time}}</td>
                    <td style="flex:1;">——</td>
                    <td style="flex:1;">{{k.time1}}</td>
                    <td style="width: 60px;text-align: center;">{{k.turnover}}</td>
                  </tr>
                </table>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="center">
          <p>快捷摘单</p>
          <div class="tableshow">
            <div id="table_body">
              <table>
                <tr>
                  <th v-for="(i,index) in quickList.title" :key="index">
                    {{i}}
                  </th>
                </tr>
                <el-scrollbar max-height="420px">
                  <tr v-for="(k,index1) in quickList.cont" :key="index1">
                    <td>{{k.name}}</td>
                    <td>{{k.num}}</td>
                    <td>{{k.price}}</td>
                    <td>{{k.toname}}</td>
                    <td>{{k.numto}}</td>
                    <td>{{k.Gmomeny}}</td>
                    <td>{{k.goodTime}}</td>
                    <td>{{k.area}}</td>
                    <td>{{k.wuliu}}</td>
                    <td>{{k.zhuang}}</td>
                    <td class="tobuy"><span>摘单</span></td>
                  </tr>
                </el-scrollbar>
              </table>
            </div>
          </div>
        </div>
        <div class="right">
          <p>资讯</p>
          <el-scrollbar max-height="440px">
            <div class="zixun">
              <h3 v-for="(i,k) in zixunList" :key="k">
                {{(k+1)+'、'}}{{i}}
              </h3>
            </div>
          </el-scrollbar>
        </div>
      </div> -->

      <div class="bodylist">
        <barter-body></barter-body>
      </div>
    </div>
  </div>
  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import { onMounted, ref } from 'vue-demi'
import http from '../../api/http'
import { homeAdvertiseList } from '../../api/api'

import HeaderBase from '../../components/HeaderBase.vue'
import Footer from '../index/Footer.vue'
import BarterBody from './BarterBody.vue'

const quickList = {
  title: ['商品名称', '商品数量', '自估价格', '拟易商品', '量换比', '保证金', '商品有效期', '交货地', '物流补偿金', '装卸补偿金', ''],
  cont: [
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 },
    { name: '利比立卡', num: '100吨', price: '10w/吨', toname: '罗布斯塔', numto: '100%', Gmomeny: '20%', goodTime: '2022-2-25', area: '上海市某某区', wuliu: 100, zhuang: 100 }
  ]
}
// 查询咨询接口
const queryPcPostByPage = (data) => {
  return http.get('/mall-portal/mall/post/queryPcPostByPage', data)
}
const getExchangeOrderList = (data) => {
  return http.get('/mall-portal/portal/pmsexchangeproduct/getExchangeOrderList')
}
export default {
  name: 'BarterMain',
  data () {
    return {
      bannerImg: ref([]),

      dataList: {
        title: [this.$t('m.barter.Name'), this.$t('m.barter.inventory'), '', this.$t('m.barter.inventory'), this.$t('m.barter.Name')],
        cont: [
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' },
          { name: '利比利卡', time: '100', time1: '200', turnover: '阿拉比卡' }
        ]
      },
      zixunList: ref([]),
      loading: ref(true)
    }
  },
  components: { HeaderBase, BarterBody, Footer },
  computed: {
    optionHover () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async initData () {
      const res = await getExchangeOrderList()
      // console.log(res)
      if (res.data.code === 200) {
        this.dataList.cont = res.data.data
      }
    },
    async zixundata () { // 初始化首页咨询数据
      const data = {
        pageNum: 1,
        pageSize: 6,
        postOrInformation: 1
      }
      const Headline = await queryPcPostByPage(data)
      // console.log(Headline)
      if (Headline.data.code === 200) {
        this.zixunList = Headline.data.data.list
        this.loading = false
      }
    },
    async getPic () {
      const data = {
        types: 1
      }
      const res = await homeAdvertiseList(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.bannerImg = res.data.data
      }
    }
  },
  mounted () {
    this.zixundata()
    this.initData()
  },
  created () {
    this.getPic()
  },
  setup (props, ctx) {
    const carousel = ref(null)
    onMounted(() => {
      setTimeout(() => {
        carousel.value.setActiveItem(0)
      }, 500)
    })
    return {
      quickList,
      carousel
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../style/viriables.scss';
.barter{
  .body{
    background: $allbgColor;
    font-family: Microsoft YaHei;
    .content{
      // height: 2000px;
      width: 1200px;
      margin: 0 auto;
      // background: $divbgColor;
      .banner{
        margin-top: 10px;
        height: 420px;
        display: flex;
        justify-content: center;
        .ban_left{
          background: #322D2B;
          div{
            background: url("../../static/imges/yushouLC01.png") no-repeat -10px;
            height: 85px;
            p{
              line-height: 75px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #322D2B;
              // z-index: 2;
              text-align: center;
            }
          }
          img{
            margin-top: 30px;
            padding: 20px;
            padding-top: 0;
            height: 200px;
            width: 200px;
          }
        }
        .ban_center{
          flex: 1;
          // margin: 0 10px;
          // box-sizing: border-box;
          .el-carousel{
            height: 420px;
            .el-carousel__container{
              height: 420px;
              .el-carousel__item{
                // height: 100%;
                  height: 420px;
                img{
                  height: 420px;
                  width: 100%;
                }
              }
            }
          }
        }
        .ban_right{
          width: 250px;
          background: #322D2B;
          box-sizing: border-box;
          p{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #C6824E;
            background: #322D2B;
            text-align: center;
            line-height: 50px;
          }
          .right_table{
            padding-top: 0;
              #table_head{
              padding-top: 15px;
              background: rgba(198, 130, 78, 0.1);
              padding: 0 20px;
              tr{
                display: flex;
                width: 100%;
                text-align: center;
                th{
                  padding: 0 1px;
                  flex: 1;
                  text-align: center;
                  font-size: 12px;
                  height: 30px;
                  line-height: 30px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  // background: rgba(198, 130, 78, 0.1);
                  color: #C6824E;
                }
              }
            }
            .seamless-warp{
              padding: 0 10px;
              height: 320px;
              overflow: hidden;
              margin: 20px 0;
              #table_body{
                table{
                  display: flex;
                  flex-flow: column;
                  tr{
                    height: 30px;
                    flex-shrink: 0;
                    box-sizing: border-box;
                    display: flex;
                    td{
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      line-height: 30px;
                      font-weight: 400;
                      color: #C6824E;
                      text-align: center;
                      // flex: 1;
                    }
                  }
                  tr:hover{
                    cursor: pointer;
                    background: rgba(255, 255, 255, 0.1);
                  }
                }
              }
            }
          }
        }
      }
      .msg{
        margin-top: 10px;
        width: 100%;
        background: $divbgColor;
        height: 100px;
        // border: 1px solid #999999;
        display: flex;
        p{
          width: 70px;
          font-size: 18px;
          font-weight: 400;
          color: #C6824E;
          background: #322D2B;
          text-align: center;
          line-height: 100px;
        }
        .zixun{
          flex: 1;
          padding: 0 10px;
          min-width: 1110px;
          max-width: 1130px;
          h3{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }

      // .msg{
      //   display: flex;
      //   margin-top: 5px;
      //   padding-bottom: 20px;
      //   background: $divbgColor;
      //   .left{
      //     border: 1px solid #999999;
      //     width: 210px;
      //     background: #F9F6F4;
      //     box-sizing: border-box;
      //     // padding: 20px;
      //     p{
      //       font-size: 18px;
      //       font-family: Microsoft YaHei;
      //       font-weight: 400;
      //       color: #C6824E;
      //       background: #322D2B;
      //       text-align: center;
      //       line-height: 50px;
      //     }
      //     .right_table{
      //       padding-top: 0;
      //       .seamless-warp{
      //         padding: 0 10px;
      //         height: 390px;
      //         overflow: hidden;
      //         margin: 20px 0;
      //         #table_body{
      //           table{
      //             display: flex;
      //             flex-flow: column;
      //             tr{
      //               height: 30px;
      //               flex-shrink: 0;
      //               box-sizing: border-box;
      //               display: flex;
      //               td{
      //                 font-size: 12px;
      //                 font-family: Microsoft YaHei;
      //                 line-height: 30px;
      //                 font-weight: 400;
      //                 color: #C6824E;
      //                 text-align: center;
      //                 // flex: 1;
      //               }
      //             }
      //             tr:hover{
      //               cursor: pointer;
      //               background: rgba(255, 255, 255, 0.1);
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .center{
      //     flex: 1;
      //     border: 1px solid #999999;
      //     p{
      //       font-size: 18px;
      //       font-weight: 400;
      //       color: #C6824E;
      //       background: #322D2B;
      //       text-align: center;
      //       line-height: 50px;
      //     }
      //     .tableshow{
      //       background: #ffffff;
      //       #table_body{
      //         box-sizing: border-box;
      //         display: flex;
      //         flex-flow: column;
      //         tr{
      //           display: flex;
      //           text-align: center;
      //           th{
      //             border: 1px solid #cccccc;
      //             flex: 1;
      //             text-align: center;
      //             font-size: 14px;
      //             height: 30px;
      //             line-height: 30px;
      //             font-weight: bold;
      //             background: #f9f6f4;
      //             color: #333333;
      //             white-space:nowrap; // 一行显示
      //             text-overflow: ellipsis;
      //             overflow: hidden;
      //           }
      //           td{
      //             border: 1px solid #cccccc;
      //             flex:1;
      //             color: #666666;
      //             font-size: 12px;
      //             height: 30px;
      //             line-height: 30px;
      //             white-space:nowrap; // 一行显示
      //             text-overflow: ellipsis;
      //             overflow: hidden;
      //           }
      //           .tobuy{
      //             span{
      //               color: #C6824E;
      //               cursor: pointer;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .right{
      //     background: #F9F6F4;
      //     width: 210px;
      //     border: 1px solid #999999;
      //     p{
      //       font-size: 18px;
      //       font-weight: 400;
      //       color: #C6824E;
      //       background: #322D2B;
      //       text-align: center;
      //       line-height: 50px;
      //     }
      //     .zixun{
      //       padding: 0 5px;
      //       // height: 390px;
      //       h3{
      //         font-size: 14px;
      //         font-weight: 400;
      //         color: #666666;
      //         line-height: 24px;
      //         text-overflow: -o-ellipsis-lastline;
      //         overflow: hidden;
      //         text-overflow: ellipsis;
      //         display: -webkit-box;
      //         -webkit-line-clamp: 3;
      //         line-clamp: 3;
      //         -webkit-box-orient: vertical;
      //       }
      //     }
      //   }
      // }
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
